body{
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    font-size: 0.8rem;
    background: rgb(5,32,56);
    background: linear-gradient(41deg, rgb(5, 28, 49) 0%, rgb(8, 52, 103) 26%, rgb(34, 153, 253) 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow-x: hidden;
    overflow-y: hidden;

    color: #a5cadc;
}

h1 {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 1.5rem;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    cursor: default;
}

h2 {
    margin: 0;
    padding: 0;
    color: #69b9ff;
    font-size: 1.15rem;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    cursor: default;
}

h3 {
    margin: 0;
    padding: 0;
    color: #69b9ff;
    font-size: 0.95rem;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    cursor: default;
}

p {
    cursor: default;
}

label {
    padding: 0;
    margin: 0;
}


a, a:hover, a:visited, a:active {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 0;
    background: #6e6e6e00;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.03);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border-left: 1px solid #00000075;
    border-top: 1px solid #00000075;
    border-bottom: 1px solid #00000075;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(52, 52, 52, 0.5);
}

.App {
    font-family: "Gill Sans Extrabold", sans-serif;

    /* FIREFOX SCROLLBAR */
    scrollbar-width: auto;
    scrollbar-color: rgba(0, 0, 0, 0.38) rgba(19, 19, 19, 0.24);
}

.emailLink {
    color: #4dacff;
    cursor: pointer;
    font-weight: bold;
}

.emailLink:hover {
    color: #9cd1ff;
}

.scrollToTopButton {
    position: fixed;
    bottom: 4rem;
    right: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    border: 3px solid #000000ad;
    background: #08080891;
    color: #a6a6a6;
    transition: 0.15s ease-in-out;
    font-size: 2rem;
    box-shadow: inset 0 0 10px 4px rgba(74, 169, 255, 0.58), 0 0 20px 7px #000000;
}

.scrollToTopButton:hover {
    transition: 0.15s ease-in-out;
    cursor: pointer;
    color: white;
    border-color: rgba(0, 0, 0, 0.87);
    background: rgba(8, 8, 8, 0.75);
    box-shadow: inset 0 0 10px 4px #4aa9ff, 0 0 20px 7px #000000;
}

.messageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    border: 2px solid #00000057;
    background: rgb(0 0 0 / 38%);
    font-size: 0.85rem;
    text-align: center;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    margin-left: 20%;
    margin-right: 20%;
}

.warningMessage {
    color: #f17f7f;
}

.infoMessage {
    color: #ffffff;
}

.labelAppButtonNormal {
    width: 100%;
    min-width: 2rem;
    height: 2rem;
    border: 1px solid black;
    background: #6393bf;
    color: #000000;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.appButtonEmpty {
    width: 100%;
    min-width: 2rem;
    height: 2rem;
    border: 1px solid black;
    background: none;
    color: #000000;
    font-weight: bold;
    -webkit-user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.appButtonNormal {
    background: #6393bf;
    width: 100%;
    min-width: 2rem;
    height: 2rem;
    border: 1px solid black;
    color: #000000;
    font-weight: bold;
    padding: 0.2rem 0.15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.appButtonSave {
    background: #6d9e6d;
    width: 100%;
    min-width: 2rem;
    height: 2rem;
    border: 1px solid black;
    color: #000000;
    font-weight: bold;
    padding: 0.2rem 0.15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.appButtonCancel {
    background: #547486;
    width: 100%;
    min-width: 2rem;
    height: 2rem;
    border: 1px solid black;
    color: #000000;
    font-weight: bold;
    padding: 0.2rem 0.15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.appButtonDelete {
    background: #c66363;
    width: 100%;
    min-width: 2rem;
    height: 2rem;
    border: 1px solid black;
    color: #000000;
    font-weight: bold;
    padding: 0.2rem 0.15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.appButtonNormal:hover,
.appButtonSave:hover,
.appButtonCancel:hover,
.appButtonDelete:hover,
.labelAppButtonNormal:hover  {
    border: 1px solid #212121;
    color: #ffffff;
    transition: 0.15s ease-in-out;
    cursor: pointer;
}

.appButtonEmpty:hover {
    color: #ffffff;
    transition: 0.15s ease-in-out;
    cursor: pointer;
}

.appButtonNormal:disabled,
.appButtonSave:disabled,
.appButtonCancel:disabled,
.appButtonDelete:disabled,
.labelAppButtonNormal:disabled  {
    border: 1px solid black;
    background: #2c2c2ca3;
    color: #696969;
    cursor: not-allowed;
}

.trashIconWrapper {
    height: 2rem;
    width: 2rem;
    margin-left: 0.15rem;

}

.appButtonTrashIcon {
    width: 1.5rem;
    height: 1.5rem;
    color: #696565;
    font-size: 0.85rem;
    font-weight: bold;
    user-select: none;
    background: none;
    border: none;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.appButtonTrashIcon:hover {
    color: #ff7171;
    background: rgba(245, 119, 119, 0.28);
    border-radius: 50%;
    cursor: pointer;
}

.appButtonTrashIcon:disabled {
    color: #696565;
    background: none
}


.innerContentWrapper {
    background: #00000040;
    padding: 1rem 15%;
    border: 1px solid black;
    margin-bottom: 1rem;
}

@media (max-width: 800px) {
    .innerContentWrapper {
        padding: 1rem 1rem;
    }
}

.innerContentTitle {
    margin: 0 0 0.5rem 0;
    text-align: center;
}

.innerEditWrapper {
    padding: 1rem 1rem;
    /*margin-bottom: 1rem;*/
    box-shadow: inset 0 0 15px #00000091;
    border-radius: 0.5rem;
}

.innerAvatarWrapper {
    display: flex;
    max-height: 10rem;
    overflow: auto;
    background: #0000001a;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    box-shadow: inset 0 0 15px #00000091;
    border-radius: 0.5rem;
}

.innerAvatarWrapper::-webkit-scrollbar-thumb {
    border-right: 1px solid #00000075;
}

.avatarImg {
    background: rgba(255, 255, 255, 0);
    padding: 0.15rem;
    margin: 0.15rem 0.6rem 0 0.6rem;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.46);
    box-shadow: inset 0 0 20px 1px rgba(0, 0, 0, 0.56);
    min-width: 6rem;
    height: 6rem;
}

.avatarImg:hover,
.avatarImg-isSelected {
    background: rgba(204, 204, 204, 0.23);
    padding: 0.15rem;
    margin: 0.15rem 0.6rem 0 0.6rem;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.84);
    box-shadow: inset 0 0 20px 1px rgba(0, 0, 0, 0.56);
    cursor: pointer;
    animation: zoomInOut 2s infinite alternate;
    min-width: 6rem;
    height: 6rem;
}

@keyframes zoomInOut {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

.entryCheckBoxWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1 1;
    margin: 1rem 0 0 0;
}

label {
    width: 100%;
}

.normalForm {
    width: 80%;
    background: #00000021;
    border: 3px solid #00000061;
    border-radius: 10px;
    margin-bottom: 2rem;
    box-shadow: 0 0 20px 2px #00000091;
}

.normalFormWide {
    width: 80%;
    background: #00000021;
    border: 3px solid #00000061;
    border-radius: 10px;
    margin-bottom: 2rem;
    box-shadow: 0 0 20px 2px #00000091;
}

select {
    border: 2px solid black;
    border-radius: 0.25rem;
    height: 2rem;
    background: #ffffff57;
    line-height: 30px;
    text-align: center;
    width: 100%;
}

select option{
    background: #669fd4;
    color: rgba(0, 0, 0, 0.5);
}

.selectOption {
    background: #669fd4;
    color: #000000;
    margin: 0.5rem;
}

input[type=text], input[type=password], input[type=number], input[type=email] {
    border: 1px solid black;
    height: 2rem;
    background: #ffffff57;
    width: 100%;
    padding-left: 0.5rem;
    touch-action: manipulation;
}

input[type=text]:disabled, input[type=password]:disabled, input[type=number]:disabled, input[type=email]:disabled {
    background: #50505057;
    color: #000000;
    cursor: not-allowed;
}

input:focus, textarea:focus, select:focus {
    outline: none;
}

input::placeholder {
    color: black;
}

.customFileInput {
    display: none;
}

.customFileInputLabel {
    display: inline-block;
    padding: 10px 15px;
    background-color: #3498db;
    color: #fff;
    border: 1px solid #2980b9;
    border-radius: 5px;
    cursor: pointer;
}

.customFileInputLabel:hover {
    background-color: #2980b9;
}

.appTextarea {
    resize: none;
    width: 100%;
    min-height: 7rem;
    background: #ffffff57;
    padding: 0.5rem;
    font-family: "Gill Sans Extrabold", sans-serif;
    font-size: 0.8rem;
    border: 1px solid black;
}

.contentSegmentWrapper {
    display: flex;
    flex-direction: row;
}

.contentSegmentLeft {
    min-width: 20%;
    max-width: 18rem;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 3px solid #091828;
    -webkit-backdrop-filter: brightness(0.85) blur(10px);
    backdrop-filter: brightness(0.85) blur(10px);
}

.innerContentLeft {
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    min-height: calc(100vh - 3rem);
}

.contentSegmentRight {
    max-height: calc(100vh - 6rem);
    width: 100%;
    overflow: auto;
    box-shadow: inset 0 0 20px -2px rgb(7 7 7 / 91%);
    height: 100vh;
}

.appFooter {
    align-items: center;
    background: linear-gradient(39deg, #051c31, #083467 26%, #2299fd) no-repeat fixed;
    border-top: 3px solid #091828;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    min-height: 3rem;
    position: fixed;
    right: 0;
    width: 80%;
    z-index: 500;
}

.appFooter.is-responsive{
    width: 100%;
}

.appFooterLink {
    font-weight: bold;
    color: #348cf1;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
}

.appFooterLink:hover {
    color: #80bbff;
    cursor: pointer;
    transition: 0.15s ease-in-out;
}

.innerContentRight {}

.innerContentHeader {
    position: relative;
    text-align: center;
    margin: 1.5rem 1rem 0.5rem 1rem;
    background: linear-gradient(to right, #00000000 0%, #00000030 40%, #00000030 40%, #00000030 60%, #00000030 60%, transparent 100%);
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.innerContentHeaderAdminButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 1.5rem;
}

.innerContentHeader::before  {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #00000000 0%, #0000005c 40%, #0000005c 40%, #0000005c 60%, #0000005c 60%, transparent 100%);
}

.innerContentHeader::after  {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #00000000 0%, #0000005c 40%, #0000005c 40%, #0000005c 60%, #0000005c 60%, transparent 100%);
}

.innerContentTitleWrapper {
    max-width: 60%;
}

.contentNavigationWrapper {
    padding: 1rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    text-align: center;
    background: linear-gradient(to bottom, rgb(0 0 0 / 42%), rgba(0, 0, 0, 0));
    box-shadow: inset 0 0 0.5rem rgb(0 0 0 / 69%);
    margin-top: 1rem;
    border-radius: 0.5rem;
}

.contentNavigationTitle {
    margin-bottom: 0.5rem;
}

.scrollNavigationWrapper {
    height: 2.5rem;
    width: 100%;
    background: #000000bf;
    position: fixed;
    z-index: 100;
    top: 2.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentNavigationSelect {
    margin-top: 0.25rem;
    width: 100%;
}

.tableBodyPreviewImage img {
    width: 100%;
    margin-top: 0.5rem;
    overflow: auto;
    max-height: 4rem;
    max-width: 5rem;
    border: 1px solid black;
    object-fit: cover;
}

.tableBodyPreviewImageId {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.tableBodyPermissionIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    font-size: 1rem;
}

.permissionIconEnabled {
    color: #ffffff;
}
.permissionIconDisabled {
    color: #ffffff14;
}

@media (min-width: 900px) {
    .contentWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        /*margin: 2rem 2rem 10rem;*/
        margin: 2rem 2rem;
    }

}

@media (max-width: 900px) {
    .contentWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem 2rem 10rem;
    }

}

@media (max-width: 900px) {
    .normalForm {
        width: 100%;
        background: #00000021;
        border: 3px solid #00000061;
        border-radius: 10px;
    }

    .normalFormWide {
        width: 100%;
        background: #00000021;
        border: 3px solid #00000061;
        border-radius: 10px;
    }
}

@media (min-width: 1500px) {
    .normalForm {
        width: 60%;
    }

    .innerContentWrapper {
        padding: 1rem 20%;
    }
}

.loginFormContent {
    padding: 1rem;
}

.formContainer {
    /*min-height: calc(70vh - 3rem);*/
    /*height: 100%;*/
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 1rem;
}

.innerEntry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.25rem 0.25rem;
}

.innerEntryButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.innerLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: default;
}

.innerEntryTable {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 1px solid #ffffff24;
    margin-top: 0.5rem;
}

.innerContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid #ffffff24;
    margin-top: 0.25rem;
}

@media (max-width: 450px) {
    .innerEntry {
        flex-direction: column;
    }

    .innerLabel {
        justify-content: left;
    }

    .entryCheckBoxWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }
}

/* ============================================================
	Responsive Table via Data Label
============================================================ */
table{
    border:0;
    width: 100%;
    margin:0;
    padding:0;
    border-collapse: collapse;
    border-spacing: 0;
    box-shadow: 0 1px 1px rgba(0,0,0,.3);
    border-bottom:1px solid #000000;
    border-top:1px solid #000000;
    text-align: center;
    thead{
        height: 30px!important;
        border-left: 1px solid #000000;
        border-right: 1px solid #000000;
        tr{
            th:first-child{
            }
            th{
                line-height: 30px!important;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                position: sticky; top: 0;
                background: #1d1d1de0;
                color: white;
                cursor: pointer;
                user-select: none;
            }
            th:hover{
                color: #6393bf;
            }
        }
    }
    tbody{
        background: rgba(0, 0, 0, 0.25);
        color: #a5cadc;
        tr{
            border-top:1px solid #000000;
            border-left:1px solid #000000;
            border-right:1px solid #000000;
            height: 40px;
            td:first-child{
            }
            td{
                line-height: 1.5rem;
                margin-top: 0.1rem;
                padding: 0 0.5rem 0 0.5rem;

                i{
                    margin-right:8px;
                }
            }
        }
    }
}

.tableBodyDescription {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
}

.tableBodyMessage {
    max-width: 200px;
}

.tableBodyGroupWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.tableBodyGroupName{

}

.tableBodyGroupUserCount {
    margin-left: 0.5rem;
    color: #ffffff40;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
}

.pagination .appButtonNormal {
    border: 1px solid black;
    height: 1.5rem;
    width: 3rem;
}

.pagination-text {
    height: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0f0f0f45;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

@media screen and (max-width: 450px) {
    h1 {
        font-size: 1.2rem;
    }

    h2 {
        font-size: 1rem;
    }

    h3 {
        font-size: 0.85rem;
    }
}

@media screen and (max-width: 900px) {
    table{
        border: 1px solid transparent;
        box-shadow: none;
        thead{
            display: none;
            tr{
                th{
                }
            }
        }
        tbody{
            tr{
                border-bottom: 2rem solid rgba(0, 0, 0, 0.38);

                td:first-child{
                    padding-left:50px;

                }
                td:before{
                    content: attr(data-label);
                    float: left;
                    text-transform: capitalize;
                    font-weight: bold;
                }
                td{
                    display: block;
                    text-align: right;
                    padding: 0 10px!important;
                    box-shadow: 0 1px 1px rgba(0,0,0,.3);
                }
            }
        }
    }

    .tableBodyDescription,
    .tableBodyMessage {
        max-width: 100%;
    }

    .tableBodyGroupWrapper {
        justify-content: flex-end;
    }
}
