.innerImg {
	border: 3px solid #091828;
	border-radius: .5rem;
	box-shadow: inset 0 0 10px 4px #4aa9ff94, 0 0 20px 7px #0000008c;
	height: auto;
	object-fit: cover;
	overflow: auto;
	width: 100%;
	max-height: 200px;
	margin-top: 1rem;
}

.overViewPreviewCommentsEntryWrapper {
	display: flex;
	flex-direction: column;
	max-height: 30rem;
	overflow-x: hidden;
	overflow-y: auto;
	margin-bottom: 1rem;
	min-height: 5.5rem;
	max-width: 100%;
	min-width: 100%;
}

.overViewUserCommentEntryWrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 1.5rem;
}

.overViewCommentsImage {
	background: #061d32;
	border: 1px solid #000;
	height: 100%;
	margin-right: .5rem;
	max-height: 5.4rem;
	max-width: 5.4rem;
	min-height: 5.4rem;
	min-width: 5.4rem;
	object-fit: cover;
	padding: .25rem;
	border-radius: 100%;
}

.overViewCommentsImage:hover,
.overViewCommentsImage:active {
	background: #b3b3b347;
	border: 1px solid #3792ff;
	cursor: pointer;
}

.overViewUserCommentEntry {
	color: white;
	width: 100%;
	background: #00000059;
	margin-bottom: 1.5rem;
	border: 1px solid black;
	box-shadow: 0 0 10px #000000;
}
.overViewUserCommentEntry:last-child {
	margin-bottom: 0;
}

.overViewUserCommentHeader {
	background: #00000075;
	padding: 0 0 0 0.5rem;
	border-bottom: 1px solid #8b939a;
	display: flex;
	align-items: center;
	height: 3rem;
}

.overViewUserCommentAuthor {
	color: #eaeaea;
	width: 100%;
	flex: 1;
	display: flex;
	justify-content: flex-start;
	font-weight: bold;
	display: flex;
	align-items: center;
	cursor: default;
}

.overViewUserCommentAuthor.is-author {
	color: #69b9ff;
}

.overViewUserCommentIcon {
	width: 2.3rem;
	height: 2.3rem;
	min-width: 2.3rem;
	min-height: 2.3rem;
	background: rgb(255 255 255 / 14%);
	box-shadow: inset 0 0 6px 2px #000000f0;
	margin-right: 0.5rem;
	border-radius: 50%;
	border: 1px solid rgb(43 43 43 / 80%);
	display: flex;
	justify-content: center;
	align-items: center;
}

.overViewUserInfo {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	width: 100%;
}

.overViewUserCommentInfo {
	display: flex;
	padding: 0.15rem 0.5rem;
	justify-content: left;
	align-items: center;
}

.overViewUserCommentMessage {
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	color: #a4d8ff;
	text-align: left;
}

@media (max-width: 1000px) {
	.overViewPreviewCommentsEntryWrapper {
		width: 100%;
	}
	
	.overViewUserInfo {
		flex-direction: column;
	}
}