.entryContainer {
    margin-bottom: 1.5rem;
}

.entryContainer h2 {
    margin-bottom: 0.15rem;
}

.innerEditTopWrapper {
    display: flex;
    flex-direction: row;
}

.innerEditTopLeft {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    margin-right: 1rem;
    width: 100%;
    max-width: 10rem;
}

.innerEditTopImg {
    height: auto;
    object-fit: cover;
    overflow: auto;
    border: 3px solid #091828;
    border-radius: 0.5rem;
    box-shadow: inset 0 0 10px 4px rgba(74, 169, 255, 0.58), 0 0 20px 7px #0000008c;
}

.innerEditTopImg.image-empty {
    font-weight: bold;
    width: 10rem;
    height: 95%;
    min-height: 10rem;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    overflow: hidden;
}

.innerTextWrapper {
    font-size: 0.85rem;
    color: #d0d0d0;
}

.innerTextWrapper p {
    margin: 0;
    padding: 0;
}

.innerTextWrapper ul li {
    margin-bottom: 0.5rem;
}

p a {
    text-decoration: none;
    text-underline: none;
    color: white;
}

p a:hover {
    color: #ababab;
}

.innerText {

}

.innerTextInfo {
    color: rgb(168 216 255 / 30%);
}

.innerDisclaimerWrapper {
    margin-top: 3rem;
}



@media screen and (max-width: 450px) {
    .innerEditTopWrapper {
        display: flex;
        flex-direction: column;
    }

    .innerEditTopLeft {
        max-width: 100%;
        width: 100%;
    }

}