.modalWrapper {
    position: absolute;
    top: 0;
    background: linear-gradient(34deg, rgb(0 0 0 / 84%) 0%, rgb(0 0 0 / 70%) 35%, rgb(0 0 0 / 47%) 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 1;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    -webkit-backdrop-filter: brightness(0.85) blur(10px);
    backdrop-filter: brightness(0.85) blur(10px);
}

.modal {
    font-family: "Gill Sans Extrabold", sans-serif;
    border: 3px solid #0000005e;
    padding: 0.25rem 0.25rem;
    margin-top: 5%;
    overflow: auto;
    max-width: 30rem;
    width: 100%;
    height: calc(100% - 25%);
    background: linear-gradient(360deg, rgb(0 0 0) 0%, rgb(27 87 159 / 70%) 35%, rgb(87 177 255 / 35%) 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    border-radius: 0.75rem;
    transition: 0.5s ease-out;
    box-shadow: 12px 18px 20px -2px rgb(0 0 0);
}

.modalTitleWrapper {
    box-shadow: inset 0 0 15px #00000091;
    border: 1px solid black;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    position: absolute;
    min-height: 2rem;
    width: calc(100% - 2rem);
    color: #69b9ff;
    font-size: 1.15rem;
}

.modalTitleLeft {
    margin-right: 0.2rem;
}
.modalTitleRight {
    margin-left: 0.2rem;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modalHeader {
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.modalIconClose {
    height: 1rem;
}

.modalContent {
    height: calc(100% - 2rem);
    position: relative;
}

.modalButton {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: none;
    background: none;
    color: rgba(0, 0, 0, 0.4);
}

.modalButton:hover {
    color: #ffffff;
    transition: 0.25s ease-in-out;
    background: #00000033;
    border-radius: 100%;
    cursor: pointer;
}

.editModalWrapper {}

.editModalEntry,
.editModalEntryUser {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: inset 0 0 15px #00000091;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
}

.editModalEntryUser {
    flex-direction: column;
}

.editModalButtons {
    position: absolute;
    bottom: 0;
    width: calc(100% - 2rem);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.entryLabel {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: bold;
    cursor: default;
    min-width: 40%;
}

.entryLabelInfoIcon {
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.25rem;
}

.editModalContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: calc(100% - 1.5rem);
    overflow: auto;
    margin-top: 0.5rem;
    height: 100%;

    /* FIREFOX SCROLLBAR */
    scrollbar-width: auto;
    scrollbar-color: rgba(0, 0, 0, 0.38) rgba(19, 19, 19, 0.24);
}



.editModalContentWrapper ::-webkit-scrollbar {
    width: 10px;
    background: #6e6e6e00;
}

.editModalContentWrapper ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.03);
}

.editModalContentWrapper ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border: 1px solid #00000075;
}

.editModalContentWrapper ::-webkit-scrollbar-thumb:hover {
    background: rgba(52, 52, 52, 0.5);
}



.editModalContent {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 1.5rem);
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #0000004f;
    padding: 0.5rem;
    height: 100%;
    margin-top: 3rem;
}

.modalFormContent {
    padding: 0 1rem 1rem 1rem;
    height: calc(100% - 1rem);
    overflow: auto;
}

.modalFormContent img{
    height: auto;
    min-height: 10rem;
    object-fit: cover;
    overflow: auto;
    border: 1px solid black;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
}

@media (max-width: 330px) {
    .editModalEntry {
        flex-direction: column;
    }

    .entryLabel {
        justify-content: left;
    }

    .modal {
        height: calc(100% - 10%);
    }

    .modalTitleWrapper {
        flex-direction: column;
    }
}

@media (max-width: 260px) {
    .editModalButtons {
        flex-direction: column;
    }

    .editUserButtonSave, .editUserButtonCancel {
        margin: 0.15rem 0;
    }

}

