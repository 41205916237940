.image-preview-container {
    position: relative;
}

.thumbnail {
    width: 100px;
    cursor: pointer;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 1001;
    -webkit-backdrop-filter: brightness(0.85) blur(10px);
    backdrop-filter: brightness(0.85) blur(10px);
}

.modal-content-image {
    width: 100%;
    height: auto;
    max-height: 95vh;
    object-fit: contain;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
}

.imageWrapper {
    position: relative;
    width: 80%;
    height: auto;
    max-height: 95vh;
    object-fit: contain;
    margin-top: 1.5rem;
    background: #0000007d;
    border: 1px solid #ffffff17;
    box-shadow: 12px 18px 20px -2px rgb(0 0 0 / 74%);
    -webkit-backdrop-filter: brightness(0.85) blur(10px);
    backdrop-filter: brightness(0.85) blur(10px);
    display: flex;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
}

.imageCloseButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background: #000000ad;
    color: white;
    border-radius: 50%;
    font-size: 1.5rem;
    margin: 0.5rem;
    transition: 0.15s ease-in-out;
    opacity: 0.75;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    border: 1px solid rgba(0, 0, 0, 0.55);
}

.imageCloseButton:hover {
    cursor: pointer;
    transition: 0.15s ease-in-out;
    opacity: 1.0;
    color: #ffffff;
    border: 1px solid rgb(0, 0, 0);
}

.imageLikeButton {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: none;
    background: #ffffff1a;
    color: white;
    border-radius: 50%;
    font-size: 1.5rem;
    margin: 0.5rem;
    transition: 0.15s ease-in-out;
    opacity: 0.75;
}


@media (max-width: 600px) {
    .modal-content {
        max-width: 100%;
    }

    .modal-content-image {
        width: 100%;
        height: auto;
        object-fit: contain;
        padding: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .imageWrapper {
        width: 100%;
        margin-top: 0;
        padding: 0;
        margin-left: 0;
        margin-right: 0;
    }

}

.prev-arrow,
.next-arrow {
    position: absolute;
    top: 50%;
    color: white;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    opacity: 0.5;
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 105;
    background: rgba(0, 0, 0, 0.62);
    border-radius: 50%;
    border: 1px solid white;
}

.prev-arrow {
    left: 3vw;
    font-size: 2rem;
}

.next-arrow {
    right: 3vw;
    font-size: 2rem;
}

.prev-arrow:hover,
.next-arrow:hover {
    transform: scale(1.4);
    transition: 0.15s ease-in-out;
    opacity: 1.0;
    box-shadow: 0 0 20px 9px rgba(255, 255, 255, 0.32);
}

.previewBottomWrapper {
    background: #3a3a3a69;
    margin: 1rem 10% 2rem 10%;
    border: 1px solid #ffffff7d;
    box-shadow: 12px 18px 20px -2px rgb(0 0 0 / 63%);
    -webkit-backdrop-filter: brightness(0.85) blur(10px);
    backdrop-filter: brightness(0.85) blur(10px);

    /* FIREFOX SCROLLBAR */
    scrollbar-width: auto;
    scrollbar-color: rgba(0, 0, 0, 0.38) rgba(19, 19, 19, 0.24);
}

.previewBottomContentWrapper {
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-height: 90vh;
    overflow: hidden;
}

.previewBottomTitle {
    margin-bottom: 0.5rem;
    text-align: center;
}

.previewBottomDescription {
    text-align: center;
    font-size: 1rem;
}

.previewBottomAdminButtons {
    display: flex;
    margin-left: -1rem;
}

.previewCommentsEntryWrapper {
    display: flex;
    flex-direction: column;
    width: 30rem;
    max-width: 30rem;
    max-height: 30rem;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 1rem;
    min-height: 5.5rem;
}

.userCommentEntry {
    color: white;
    width: 100%;
    background: #00000059;
    margin-bottom: 1.5rem;
    border: 1px solid black;
    box-shadow: 0 0 10px #000000;
}
.userCommentEntry:last-child {
    margin-bottom: 0;
}

.userCommentHeader {
    background: #00000075;
    padding: 0 0 0 0.5rem;
    border-bottom: 1px solid #8b939a;
    display: flex;
    align-items: center;
    height: 3rem;
}

.userCommentInfo {}
.userCommentAuthor {
    color: #eaeaea;
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: default;
}

.userCommentIcon {
    width: 2.3rem;
    height: 2.3rem;
    min-width: 2.3rem;
    min-height: 2.3rem;
    background: rgb(255 255 255 / 14%);
    box-shadow: inset 0 0 6px 2px #000000f0;
    margin-right: 0.5rem;
    border-radius: 50%;
    border: 1px solid rgb(43 43 43 / 80%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.userInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.userCommentAuthor.is-author {
    color: #69b9ff;
}

.userCommentTime {
    flex: 1;
    color: #4c4c4c;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: default;
}

.userCommentMessage,
.userCommentMessage-empty {
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: #838383;
    text-align: left;
}

.userCommentMessage-empty {
    justify-content: center;
}

.userCommentTextareaWrapper {
    width: 30rem;
    max-width: 30rem;
    background: #00000059;
    padding: 1rem;
}

.previewBottomTitleAndDescriptionWrapper {
    background: #00000073;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #ffffff7d;
    box-shadow: inset 0px 0px 30px #000000;
    height: 6rem;
}

.userDescriptionWrapper {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.userDescriptionWrapper .previewBottomTitle {
    color: #ffffff;
}

.userDescriptionTitleWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-left: 2rem;
}

.userCommentTextarea {
    resize: none;
    width: 100%;
    min-height: 7rem;
    background: #50505057;
    color: white;
    padding: 1rem;
    margin-bottom: 1rem;
}

.userCommentTextarea:focus {
    border-radius: 0;
    border: 1px solid white;
}

.userCommentInfo {
    display: flex;
    padding: 0.15rem 0.5rem;
    justify-content: left;
    align-items: center;
}

@media (max-width: 1000px) {
    .userCommentTextareaWrapper,
    .previewCommentsEntryWrapper {
        width: 100%;
    }

    /* Remove Scale and Zoom on responsive view */
    .next-arrow, .next-arrow:hover,
    .prev-arrow, .prev-arrow:hover {
        transition: none;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
    }
}

@media (max-width: 600px) {
    .prev-arrow,
    .next-arrow {
        transform: scale(1.2);
        top: 50%;
    }

    .prev-arrow {
        left: 5vw;
    }
    .next-arrow {
        right: 5vw;
    }
}

.previewBottomWrapper ::-webkit-scrollbar {
    width: 10px;
    background: #6e6e6e00;
}

.previewBottomWrapper ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.03);
}

.previewBottomWrapper ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border: 1px solid #00000075;
}

.previewBottomWrapper ::-webkit-scrollbar-thumb:hover {
    background: rgba(52, 52, 52, 0.5);
}

@media (max-width: 600px) {
    .previewBottomWrapper {
        margin: 0;
    }

}