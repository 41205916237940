.DropdownWrapper {
    width: 10rem;
}

.DropdownButton {
    background: #00000047;
    color: #45b9ff;
    border: none;
    text-decoration: none;
    cursor: pointer;
    height: 100%;
    font-weight: bold;
    border-left: 3px solid #091828;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10rem;
    position: absolute;
    top: 0;
    right: 0;
}

.DropdownMenu {
    min-width: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: rgb(16 16 16 / 93%);
    border: 3px solid #091828;
    box-sizing: border-box;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1001;
    -webkit-overflow-scrolling: touch;
}

.dropdownEntry {
    padding: 8px 10px;
    cursor: pointer;
    color: #8f8f8f;
    font-weight: bold;
}

.dropdownEntry:hover {
    color: #eeeeee;
    cursor: pointer;
}

.dropdownTitle {
    padding: 8px 8px;
    text-transform: capitalize;
    color: #45b9ff;
    background: #194770b8;
    cursor: default;
    font-weight: bold;
}

.DropdownOption-logout {
    padding: 8px 8px;
    color: #ffcdcd;
    text-shadow: -1px -1px 0 #00000096, 1px -1px 0 #00000096, -1px 1px 0 #00000096, 1px 1px 0 #00000096;
    background: rgba(139, 68, 68, 0.74);
    cursor: pointer;
    border-top: 1px solid black;
}

.DropdownOption-logout:hover {
    color: #ffcdcd;
    text-shadow: -1px -1px 0 #00000096, 1px -1px 0 #00000096, -1px 1px 0 #00000096, 1px 1px 0 #00000096;
    background: rgba(105, 41, 41, 0.7);
}

.dropdownEntry-link{
    height: 100%;
    width: 100%;
    color: #414141;
    text-decoration: none;
    border: 1px solid black;
}

.dropdownEntry-link:hover {
    color: black;
    transition: 0.3s ease-in-out;
    text-decoration: none;
}


@media screen and (max-width: 600px) {
    .DropdownButton {
        font-size: 0;
        width: 3rem;
    }

    .DropdownMenu {
        min-width: 100%;
    }

    .DropdownButton::before {
        content: '\2630'; /* Unicode-Zeichen */
        font-size: 20px;
    }

    .dropdownEntry {
        padding: 15px 12px;
        border: 1px solid #1f1f1f;
    }
}