input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    border: 1px solid #000;
    background-color: #ffffff57;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    color: white;
    padding: 0 0 0.15rem;
}

input[type="checkbox"]:checked {
    background-color: #212121;
}

input[type="checkbox"]:checked:after {
    content: '\2714'
}

/* WARNING CHECKBOX */
.checkboxWarning {
    background-color: rgba(218, 86, 86, 0.34) !important;
    color: white !important;
    content: '\0021' !important;
}

.checkboxWarning:checked {
    background-color: #833c3c !important;
}

.checkboxWarning:checked::after {}