.loginForm {
    max-width: 20rem;
    width: 100%;
    background: #00000021;
    border: 3px solid #00000061;
    border-radius: 10px;
    box-shadow: 0 0 20px 2px #00000091;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.inputEntry {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.inputEntryPasswordWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inputEntryPassword{
    width: 100%;
}

.submitButton {
    background: #0000008c;
    padding: 0.3rem 0.45rem;
    color: white;
    border: 1px solid black;
    text-decoration: none;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-top: 0.5rem;
    min-width: 50%;
}

.submitButton:hover {
    border: 1px solid #37b5ff;
    background: #00000069;
}

.loginTitle {
    box-shadow: inset 0 0 15px #00000091;
    border: 1px solid black;
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.formButtonWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.submitButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.formButtonRegisterWrapper {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.registerInfo {
    color: #5fc2ff;
    cursor: default;
    margin-bottom: 1.25rem;
}

.registerInfo:last-child {
    margin-bottom: 0;
}

.registerInfoAccountCreate {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.registerInfoPasswordReset {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.registerLink {
    color: #d5d5d5;
    display: flex;
    justify-content: center;
}

.registerLink:hover {
    color: #ffffff;
}

.formLabel {
    display: flex;
    color: #b8e1ff;
    font-weight: bold;
}

.responseMessage_error {
    color: #ff8a8a;
    text-align: center;
    border-radius: 0.25rem;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    animation: pulse 2s infinite;
}

.responseMessage_success {
    color: #8af9a3;
    text-align: center;
    border-radius: 0.25rem;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
    }

    70% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0.95);
    }
}

.lds-ellipsis {
    display: flex;
    position: relative;
    width: 75px;
    height: 28px;
    justify-content: center;
    align-items: center;
}

.lds-ellipsis div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

@media (max-width: 330px) {
    .registerInfoPasswordReset {
        flex-direction: column;
    }
}
