.cookie-acceptance {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-direction: column;
    width: 100%;
    background: #000000b0;
    bottom: -100%;
    padding-bottom: 1rem;
    border-top: 3px solid #6393bf;
    padding-top: 1rem;
    font-family: "Gill Sans Extrabold", sans-serif;
    font-size: 0.85rem;
    transition: bottom 3s ease-in-out;
}

.cookie-acceptance.show {
    bottom: 0;
}

.cookie-acceptance-message {
    margin: 0 2rem 1rem 2rem;
    text-align: center;
}