.custom-select {
    position: relative;
    width: 100%;
    text-align: center;
    display: flex;
    user-select: none;
}

.disabled-div {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.custom-select select {
    display: none;
}

.select-selected {
    background-color: DodgerBlue;
}

.select-selected:after,
.select-selected-isCentered:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
}

.select-selected.select-arrow-active:after,
.select-selected-isCentered.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}

.select-selected {
    padding: 8px 0.5rem;
    cursor: pointer;
    width: 100%;
    height: 2rem;
    border: 1px solid black;
    background: #ffffff57;
    color: #000000;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
}

.select-selected-isCentered {
    padding: 8px 0.5rem;
    cursor: pointer;
    width: 100%;
    height: 2rem;
    border: 1px solid black;
    background: #ffffff57;
    color: #000000;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    font-weight: bold;
}

.select-option {
    color: #ffffff;
    padding: 8px 16px;
    border-bottom: 2px solid #ffffff0f;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select-option:hover {
    color: #8cceff;
}

.select-items {
    position: absolute;
    background-color: #18181896;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    -webkit-backdrop-filter: brightness(0.85) blur(10px);
    backdrop-filter: brightness(0.85) blur(10px);
}

.select-hide {
    display: none;
}

.select-items div:hover, .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 900px) {
}


@media (max-width: 1025px) {
    .select-option {
        padding: 16px 16px;
    }

}