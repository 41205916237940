.navBarUl {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 3rem;
    width: 100%;
    backdrop-filter: brightness(0.85) blur(10px);
    background-color: #3d3d3d45;
    border-bottom: 3px solid #091828;
    transition: 0.3s ease-out;
}

.navBarUlContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.leftPart {
    display: flex;
    /*width: 50%;*/
    list-style-type: none;
    height: 100%;
    align-items: center;
}

.middlePart {
    display: flex;
    justify-content: center;
    list-style-type: none;
    height: 100%;
    align-items: center;
}

.rightPart {
    list-style-type: none;
    display: flex;
    /*width: 50%;*/
    justify-content: right;
    height: 100%;
    align-items: center;
}

.welcomeGreeting {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    font-weight: bold;
    color: #8cceff;
    cursor: pointer;
}

.welcomeGreeting:hover {
    color: #f7f9fb;
}

.welcomeGreeting:hover .welcomeGreetingIcon {
    box-shadow: inset 0 0 6px 2px rgb(0, 0, 0), 0 0 6px 2px rgba(0, 0, 0, 0.68);
}

.welcomeGreetingIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.4rem;
    width: 2.4rem;
    background: rgb(255 255 255 / 14%);
    box-shadow: inset 0 0 6px 2px #000000f0;
    margin-left: 0.5rem;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.8);
    color: white;
}

.appHeader {
    height: 3rem;
    position: relative;
    z-index: 100;
}

.navBarWrapper {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
}

.navBarLink {
    min-width: 5%;
    width: 100%;
    box-shadow: inset 0 0 15px rgba(169, 167, 167, 0.57);
    border-radius: 1rem;
    text-decoration: none;
}

.navBarLink:hover {
    box-shadow: inset 0 0 15px #00000091;
    transition: 0.3s ease-in-out;
    text-decoration: none;
}

.navBarHome {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000b5;
    box-shadow: inset 0 0 15px #00000091;
    padding: 0.5rem;
    border-right: 3px solid #091828;
    background: #7d7d7d59;
    width: 60px;
    min-width: 60px;
    height: 100%;
}

.navBarButton-dark,
.navBarButton-red,
.navBarButton-logout,
.navBarButton-login,
.navBarButton-aboutMe,
.navBarButton-comments {
    background: #00000000;
    padding: 0.15rem 0.25rem;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border: none;
    text-decoration: none;
    cursor: pointer;
    height: 48px;
    width: 100%;
    min-width: 100px;
}

.navBarButton-dark {
    color: #45b9ff;
}

.navBarButton-red {
    color: #ff8787;
}

.navBarButton-logout {
    background: #00000047;
    color: #ff8787;
    border-left: 3px solid #00000026;
}

.navBarButton-login {
    background: #00000047;
    color: #45b9ff;
    border-left: 3px solid #091828;
}

.navBarButton-login:hover,
.navBarButton-login:active {
    background: rgba(0, 0, 0, 0.32);
    color: #ffffff;
    border-left: 3px solid #091828;
}

.navBarButton-comments {
    background: #00000047;
    color: #45b9ff;
    border-right: 3px solid #091828;
}

.navBarButton-comments:hover,
.navBarButton-comments:active {
    background: rgba(0, 0, 0, 0.32);
    color: #ffffff;
    border-right: 3px solid #091828;
}

.navBarButton-aboutMe {
    background: rgba(0, 0, 0, 0.16);
    color: #45b9ff;
    border-right: 3px solid #091828;
}

.navBarButton-aboutMe:hover,
.navBarButton-aboutMe:active {
    background: rgba(0, 0, 0, 0.32);
    color: #ffffff;
    border-right: 3px solid #091828;
}

.navBarSegmentButtonWrapper {
    min-width: 150px;
}

.rainbow {
    /* Chrome, Safari, Opera */
    -webkit-animation: rainbow 10s infinite;
    /* Internet Explorer */
    -ms-animation: rainbow 10s infinite;
    /* Standard Syntax */
    animation: rainbow 10s infinite;
    height: 2rem;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes rainbow {
    0% {
        color: #e5eff3;
    }
    10% {
        color: #b9e1f1;
    }
    20% {
        color: #9ad7ef;
    }
    30% {
        color: #7ed1f1;
    }
    40% {
        color: #63cbf3;
    }
    50% {
        color: #43c1f1;
    }
    60% {
        color: #63cbf3;
    }
    70% {
        color: #7ed1f1;
    }
    80% {
        color: #9ad7ef;
    }
    90% {
        color: #b9e1f1;
    }
    100% {
        color: #e5eff3;
    }
}


/* Internet Explorer */

@-ms-keyframes rainbow {
    0% {
        color: #e5eff3;
    }
    10% {
        color: #b9e1f1;
    }
    20% {
        color: #9ad7ef;
    }
    30% {
        color: #7ed1f1;
    }
    40% {
        color: #63cbf3;
    }
    50% {
        color: #43c1f1;
    }
    60% {
        color: #63cbf3;
    }
    70% {
        color: #7ed1f1;
    }
    80% {
        color: #9ad7ef;
    }
    90% {
        color: #b9e1f1;
    }
    100% {
        color: #e5eff3;
    }
}

@keyframes rainbow {
    0% {
        color: #e5eff3;
        transform: scale( .75 );
    }
    10% {
        color: #b9e1f1;

    }
    20% {
        color: #9ad7ef;

    }
    30% {
        color: #7ed1f1;

    }
    40% {
        color: #63cbf3;
        transform: scale( 1 );
    }
    50% {
        color: #43c1f1;

    }
    60% {
        color: #63cbf3;

    }
    70% {
        color: #7ed1f1;
        transform: scale( 1 );
    }
    80% {
        color: #9ad7ef;
    }
    90% {
        color: #b9e1f1;
    }
    100% {
        color: #e5eff3;
        transform: scale( .75 );
    }
}

.rainbow:hover {
    color: #00d2ff;
    -webkit-animation: 0;
    -ms-animation: 0;
    animation: 0;
}

@media screen and (max-width: 600px) {
    .welcomeGreeting {
        display: none;
    }

}