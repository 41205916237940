.uploadImages {
    display: flex;
    flex-direction: column;
}

.uploadImageContainer {
    width: 5rem;
    height: 5rem;
    overflow: hidden;
}

.uploadHeaderWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.uploadHeaderWrapper .select-selected {
    text-align: center;
    font-weight: bold;
    background: #6393bf;
}

.uploadHeaderWrapper .select-selected:hover {
    color: #ffffff;
    transition: 0.15s ease-in-out;
}


.uploadPreviewImage {
    width: 100%;
    height: auto;
}

.uploadPreviewWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0.5rem;
}

.uploadPreviewEntry {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(10% - 20px);
    background: #0000006b;
    padding: 0.25rem;
    border: 1px solid black;
}

.dataEntryWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem;
    box-shadow: inset 0 0 15px #00000091;
    border: 1px solid black;
    margin-bottom: 0.5rem;
}

.dataInfoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.dataInfoTitle {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.dataInfoValues {}

@media (max-width: 800px) {
    .uploadPreviewEntry {
        margin-bottom: 20px;
    }
}

@media (max-width: 512px) {
    .uploadHeaderWrapper {
        flex-direction: column;
    }
}