.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltipText {
    visibility: hidden;
    width: 120px;
    background-color: #161616eb;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 101;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 0.85rem;
}

.tooltip:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}

.tooltipImage {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltipImage .tooltipImageItem {
    visibility: hidden;
    background-color: #161616eb;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 100;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 0.85rem;
}

.tooltipImage:hover .tooltipImageItem {
    visibility: visible;
    opacity: 1.0;
    height: auto;
    object-fit: cover;
    overflow: auto;
}
