.imageContainerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.imageContainer {
    border: 5px solid #000000b5;
    box-shadow: 0 0 10px #000000;
    position: relative;
    overflow: hidden;
    min-height: 2rem;
    border-radius: 0.5rem;
    transition: 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 80;
}

.imageContainer:hover {
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.94);
    border: 5px solid #000000b5;
    cursor: pointer;
    transition: 0.25s ease-in-out;
}

.parallaxWrapper {
    position: relative;
    z-index: 10;
    flex: 1 0 100%; /* Flex-Grow: 1, Flex-Shrink: 0, Basisgröße: 200px */
    margin: 0.5rem;
    transition: transform 0.3s ease;
}

.parallaxWrapper:hover {
    position: relative;
    z-index: 20;
    transform: scale(1.1);
}

.bottomInfo {
    position: absolute;
    z-index: 100;
    bottom: 0;
    width: 100%;
    height: 1.85rem;
    color: #e1e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-backdrop-filter: brightness(.85) blur(10px);
    backdrop-filter: brightness(1) blur(10px);
    background: #00000045;
    border-top: 1px solid #000000;
}

.infoItemCategory {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 10;
    padding: 0.25rem;
    color: black;
    font-weight: bold;
    font-size: 0.65rem;
    background: #ffffffb3;
    border-bottom-right-radius: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    transition: 0.15s ease-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.infoItemCategory:hover {
    background: #55b7ff;
    z-index: 10;
    padding: 0.5rem 0.8rem;
}

.infoItemDelete {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    padding: 0;
    color: black;
    font-weight: bold;
    background: rgba(28, 27, 27, 0.7);
    border-bottom-left-radius: 0.5rem;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    transition: 0.15s ease-out;
}

.infoItemDeleteIcon {
    background: none;
    border: none;
    padding: 0.35rem 0.35rem 0.35rem 0.45rem;
    color: #ffa0a0;
    font-size: 0.8rem;
    transition: 0.15s ease-out;
}

.infoItemDeleteIcon:hover {
    background: none;
    padding: 0.75rem;
    border: none;
    color: #ffa0a0;
    cursor: pointer;
    font-size: 1.15rem;
}

.infoIconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.infoItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    text-shadow: 0 0 3px #000;
}

.infoItem.is-noComments,
.infoItem.noName {
    opacity: 30%;
}

.infoItem.has-likes {
    opacity: 100%; /* Normale Sichtbarkeit */
}

.infoItem.is-liked {
    color: #55c8ff;
    opacity: 100%; /* Normale Sichtbarkeit */
}

.infoItem.is-not-liked {
    opacity: 100%; /* Normale Sichtbarkeit */
}

.infoItem.has-no-likes {
    opacity: 30%; /* Transparenter */
}

.infoItem:first-child {
    padding-left: 0.5rem;
    justify-content: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.infoItem:not(:first-child):not(:last-child) {
    justify-content: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}
.infoItem:last-child {
    padding-right: 0.5rem;
    justify-content: right;
    max-width: 5rem;
}

.infoIconComment {
    height: 1rem;
    margin-left: 0.15rem;
}

.infoIconLike {
    height: 1rem;
    margin-left: 0.15rem;
    margin-right: 0.5rem;
}

.infoIconLike:hover {
    height: 1rem;
    margin-left: 0.15rem;
    margin-right: 0.5rem;
    transform: scale(1.05);
    color: rgba(166, 221, 255, 0.8);
}

.infoIconShowPassword {
    height: 1rem;
    width: 1rem;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
}

* {
    box-sizing: border-box;
}

.row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 2rem 2rem 10rem 2rem;
}

.column {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
    max-width: 25%;
}

.column img {
    width: 100%;
}

/* Desktop layout - Change height for container */
@media screen and (min-width: 800px) {
    .imageContainer {
        max-height: 30vh;
        min-height: 6rem;
    }

}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 1100px) {
    .column {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
    }

    .imageContainer {
        max-height: 15rem;
    }

    .infoIconLike,
    .infoIconLike:hover {
        height: 1.5rem;
    }

    .infoItem.is-not-liked,
    .infoItem.is-liked {
        min-width: 3.5rem;
    }
    
    .infoItem.has-likes {
        color: #e1e1e1;
    }

    .bottomInfo {
        height: 2.5rem;
    }

}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .column {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
    }

    .imageContainer {
        max-height: 50vh;
    }

}

div.imageScaleWrapper {}

div.imageScaleWrapper img {
    width: 100%;
    height: auto;

    /* SCALE */
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    /* DELAY */
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}
div.imageScaleWrapper img:hover {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}

.loadMoreButtonWrapper {
    width: 100%;
    height: 3rem;
    margin: 0.5rem;
}

.loadMoreButton {
    color: #000000;
    width: 100%;
    height: 3rem;
    border: 2px solid #ffffff45;
    background: rgb(255 255 255 / 14%);
    -webkit-user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    border-radius: 0.5rem;
    font-weight: bold;
    opacity: 0.65;
}

.loadMoreButton:hover  {
    color: #ffffff;
    border: 2px solid rgba(255, 255, 255, 0.76);
    transition: 0.15s ease-in-out;
    cursor: pointer;
    opacity: 1.0;
}

/* Remove Scale and Zoom on responsive view */
@media screen and (max-width: 1000px) {
    .imageContainer, .imageContainer:hover,
    .parallaxWrapper, .parallaxWrapper:hover,
    div.imageScaleWrapper img, div.imageScaleWrapper img:hover {
        transition: none;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
    }
}


