.navigationItemWrapper {
    margin-top: 1.5rem;
    padding-bottom: calc(4.5rem - 1px);
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 3rem);

    /* FIREFOX SCROLLBAR */
    scrollbar-width: auto;
    scrollbar-color: rgba(0, 0, 0, 0.38) rgba(19, 19, 19, 0.24);
}

.navigationItemWrapper::-webkit-scrollbar {
    width: 10px;
    background: #6e6e6e00;

    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.25) rgba(255, 255, 255, 0.03);
}

/* Track */
.navigationItemWrapper::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.03);
    border-left: 1px solid #00000075;
}

/* Handle */
.navigationItemWrapper::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
    border-left: 1px solid #00000075;
    border-top: 1px solid #00000075;
    border-bottom: 1px solid #00000075;
}

/* Handle on hover */
.navigationItemWrapper::-webkit-scrollbar-thumb:hover {
    background: rgba(52, 52, 52, 0.5);
}







.searchAndHomeWrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.navigationSearchInputInfo {
    text-align: center;
    padding: 0;
    margin: 0;
}

.navigationItem, .navigationItem-active {
    cursor: pointer;
    transition: 0.25s ease-out;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
    opacity: 0.5;
    margin-top: 0.25rem;
    border-bottom: 1px solid rgb(0, 0, 0);
    border-top: 1px solid rgb(0, 0, 0);
}

.navigationItem:hover, .navigationItem-active {
    transition: 0.15s ease-out;
    z-index: 5;
    position: relative;
    opacity: 1;
}

.navigationItem a, .navigationItem-active a {
    width: 100%;
    height: 3.5rem;
}

.navigationImage, .navigationImage-active {
    width: 100%;
    position: relative;
    z-index: 1;
    height: 8rem;
    object-fit: cover;
}

.navigationImage:hover, .navigationImage-active {
    position: relative;
    z-index: 1;
}

.navigationImageEmpty, .navigationImageEmpty-active {
    height: 3.5rem;
}

.navigationText, .navigationText-active {
    color: rgb(255, 255, 255);
    position: absolute;
    z-index: 4;
    width: 100%;
    transition: 0.25s ease-out;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 1.5rem;
    height: 3.5rem;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
    font-size: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.navigationText:hover, .navigationText-active {
    color: white;
    position: absolute;
    padding-left: 2.8rem;
    z-index: 5;
    background: linear-gradient(to right, rgb(0 0 0 / 60%), rgba(0, 0, 0, 0));
    font-size: 0.9rem;
    box-shadow: inset -4px 5px 12px rgb(255 255 255 / 23%), inset 8px -6px 20px rgb(0 0 0 / 30%);
}

.navigationHome, .navigationHome-active {
    color: white;
    position: absolute;
    z-index: 5;
    font-size: 0.75rem;
    padding-left: 1.5rem;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
    transition: 0.25s ease-out;
    display: flex;
    justify-content: left;
    align-items: center;
    height: 3.5rem;
    width: 100%;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.navigationHome:hover, .navigationHome-active {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 2.8rem;
    font-size: 0.9rem;
    background: linear-gradient(to right, rgb(0 0 0 / 60%), rgba(0, 0, 0, 0));
    box-shadow: inset -4px 5px 12px rgb(255 255 255 / 23%), inset 8px -6px 20px rgb(0 0 0 / 30%);
    color: white;
    position: absolute;
    z-index: 5;
}

.navigationSearchWrapper {
    transition: height 0.3s ease;
}

.navigationSearchWrapper.collapsed {}

.toggleButton {
    cursor: pointer;
}

.collapseButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    font-size: 1rem;
    background: #1a436c;
    border-bottom: 3px solid #091828;
}


.navigationHome-active {
    position: relative;
    width: 100%;
    height: 3.5rem;
    line-height: 3.5rem;
    text-align: center;
    color: #252B37;
    animation: textColor 10s ease infinite;
}

.navigationHomeItem:after {
    position: absolute;
    content: "";
    top: -20rem;
    left: 0;
    right: 0;
    z-index: -1;
    height: 50%;
    width: 100%;
    margin: 0 auto;
    transform: scale(0.75);
    -webkit-filter: blur(8vw);
    -moz-filter: blur(8vw);
    -ms-filter: blur(8vw);
    filter: blur(8vw);
    background: linear-gradient(270deg, #4dbff8, rgb(152, 240, 255));
    background-size: 250% 250%;
    animation: animateGlow 6s ease infinite;
}

@keyframes animateGlow {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

@keyframes textColor {
    0% { color: rgb(248, 244, 244); }
    50% { color: #4dbff8; }
    100% { color: rgb(248, 244, 244); }
}
